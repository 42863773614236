/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',

  waitingAPI: 'dating-notification-pp.dv-content.io/',
  waitingToken: 'TtD4dFtMCfu92eCK',
  gtm: 'GTM-M8H633C',
  gtmCountry: 'GTM-KXF2T86',
  completeProfileInputs: ['phoneNumber'],
  id: '42a1d48c-c03b-4d5e-a36b-79c527710716',
  dvp4m: false,
  gsv: null,

  appLink: null,
  didomi: null,
};
